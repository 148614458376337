import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getAllAfSuccess,
  getAfGlobalSuccess,
  getAfFilteredSuccess,
} from './actions';

// Action Types
import { GET_AF, GET_AF_GLOBAL, AF_FILTER_BY_VALUE } from './actionsTypes';

// Methods
import { getAfRequest, getAfGlobalRequest } from '../../helpers/api/AfData';

// Get
function* getAf() {
  try {
    const response = yield call(getAfRequest, '/overview/af');

    yield put(getAllAfSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAfGlobal() {
  try {
    const response = yield call(getAfGlobalRequest, '/overview/af/global');

    yield put(getAfGlobalSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getAfFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetAf() {
  yield takeEvery(GET_AF, getAf);
}

export function* watchGetAfGlobal() {
  yield takeEvery(GET_AF_GLOBAL, getAfGlobal);
}

export function* watchGetFiltering() {
  yield takeEvery(AF_FILTER_BY_VALUE, getFiltered);
}

function* afSaga() {
  yield all([
    fork(watchGetAf),
    fork(watchGetAfGlobal),
    fork(watchGetFiltering),
  ]);
}

export default afSaga;
