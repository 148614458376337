import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getAllNumbersSuccess,
  getNumbersFilteredSuccess,
} from './actions';

// Action Types
import { GET_NUMBERS, NUM_FILTER_BY_VALUE } from './actionsTypes';

// Methods
import { getNumbersRequest } from '../../helpers/api/Numbers';

// Get
function* getNumbers(data) {
  try {
    const response = yield call(getNumbersRequest, '/numbers', data.payload);

    yield put(getAllNumbersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getNumbersFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetNumbers() {
  yield takeEvery(GET_NUMBERS, getNumbers);
}

export function* watchGetFiltering() {
  yield takeEvery(NUM_FILTER_BY_VALUE, getFiltered);
}

function* numbersSaga() {
  yield all([fork(watchGetNumbers), fork(watchGetFiltering)]);
}

export default numbersSaga;
