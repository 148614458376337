import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getAllCampaignsSuccess,
  getAllActiveCampaignsSuccess,
  getCurrCampaignSuccess,
  patchCampaignSuccess,
  postCampaignSuccess,
  getCampaignsFilteredSuccess,
} from './actions';

// Action Types
import {
  GET_CAMPAIGNS,
  GET_ACTIVE_CAMPAIGNS,
  GET_CURR_CAMPAIGN,
  PATCH_CAMPAIGN,
  CAMP_FILTER_BY_VALUE,
  POST_CAMPAIGN,
} from './actionsTypes';

// Methods
import {
  getCampaignsRequest,
  getActiveCampaignsRequest,
  getCurrCampaignRequest,
  patchCampaignRequest,
  postCampaignRequest,
} from '../../helpers/api/Campaigns';
import checkFieldValues from '../../helpers/checkings/checkFieldValues';
import {
  toastHandlerError,
  toastHandlerSuccess,
} from '../../helpers/toastHandler/toastHandler';

// Get
function* getCampaigns() {
  try {
    const response = yield call(getCampaignsRequest, '/campaigns');

    yield put(getAllCampaignsSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
// Get Active
function* getActiveCampaigns() {
  try {
    const response = yield call(getActiveCampaignsRequest, '/campaigns/active');

    yield put(getAllActiveCampaignsSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Get Curr
function* getCurrCampaign(data) {
  try {
    const response = yield call(
      getCurrCampaignRequest,
      `/campaigns/${data.payload}`
    );

    yield put(getCurrCampaignSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Patch
function* patchCampaign(data) {
  try {
    const response = yield call(
      patchCampaignRequest,
      `/campaigns/${data.payload.id}`,
      checkFieldValues(data.payload.data)
    );

    toastHandlerSuccess(`Campaign ${response.id} updated successfully!`);

    yield put(patchCampaignSuccess(response));
  } catch (error) {
    let errorMessage = `Error updating campaign ${data.payload.id}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

// Post
function* postCampaign(data) {
  try {
    const response = yield call(
      postCampaignRequest,
      '/campaigns',
      data.payload
    );

    toastHandlerSuccess(`Campaign ${response.id} created successfully!`);
    yield put(postCampaignSuccess(response));
  } catch (error) {
    let errorMessage = `Error creating campaign ${data.payload.id}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getCampaignsFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetCampaigns() {
  yield takeEvery(GET_CAMPAIGNS, getCampaigns);
}

export function* watchGetActiveCampaigns() {
  yield takeEvery(GET_ACTIVE_CAMPAIGNS, getActiveCampaigns);
}

export function* watchGetCurrCampaign() {
  yield takeEvery(GET_CURR_CAMPAIGN, getCurrCampaign);
}

export function* watchPatchCampaign() {
  yield takeEvery(PATCH_CAMPAIGN, patchCampaign);
}

export function* watchPostCampaign() {
  yield takeEvery(POST_CAMPAIGN, postCampaign);
}

export function* watchGetFiltering() {
  yield takeEvery(CAMP_FILTER_BY_VALUE, getFiltered);
}

function* campaignsSaga() {
  yield all([
    fork(watchGetCampaigns),
    fork(watchGetActiveCampaigns),
    fork(watchPostCampaign),
    fork(watchGetCurrCampaign),
    fork(watchPatchCampaign),
    fork(watchGetFiltering),
  ]);
}

export default campaignsSaga;
