import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Advertisers
const getAdvertisersRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Current Advertiser
const getCurrAdvertiserRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Patch Advertisers
const patchAdvertiserRequest = (url, data) => {
  return axios
    .put(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Advertisers
const postAdvertiserRequest = (url, data) => {
  return axios
    .post(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Advertisers
const deleteAdvertiserRequest = (url) => {
  return axios
    .delete(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export {
  getAdvertisersRequest,
  getCurrAdvertiserRequest,
  patchAdvertiserRequest,
  postAdvertiserRequest,
  deleteAdvertiserRequest,
};
