import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getAllOffersSuccess,
  getCurrOfferSuccess,
  patchOfferSuccess,
  postOfferSuccess,
  getOffersFilteredSuccess,
} from './actions';

// Action Types
import {
  GET_OFFERS,
  GET_CURR_OFFER,
  PATCH_OFFER,
  OFF_FILTER_BY_VALUE,
  POST_OFFER,
} from './actionsTypes';

// Methods
import {
  getOffersRequest,
  getCurrOfferRequest,
  patchOfferRequest,
  postOfferRequest,
} from '../../helpers/api/Offers';
import checkFieldValues from '../../helpers/checkings/checkFieldValues';
import {
  toastHandlerError,
  toastHandlerSuccess,
} from '../../helpers/toastHandler/toastHandler';

// Get
function* getOffers() {
  try {
    const response = yield call(getOffersRequest, '/offers');

    yield put(getAllOffersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Get Curr
function* getCurrOffer(data) {
  try {
    const response = yield call(getCurrOfferRequest, `/offers/${data.payload}`);

    yield put(getCurrOfferSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Patch
function* patchOffer(data) {
  try {
    const response = yield call(
      patchOfferRequest,
      `/offers/${data.payload.id}`,
      checkFieldValues(data.payload.data)
    );

    toastHandlerSuccess(`Offer ${response.name} updated successfully!`);

    yield put(patchOfferSuccess(response));
  } catch (error) {
    let errorMessage = `Error updating offer ${data.payload.id}`;
    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }
    toastHandlerError(errorMessage);
    yield put(apiError(error));
  }
}

// Post
function* postOffer(data) {
  try {
    const response = yield call(postOfferRequest, '/offers', data.payload);

    toastHandlerSuccess(`Offer ${response.name} created successfully!`);

    yield put(postOfferSuccess(response));
  } catch (error) {
    console.log(error);
    let errorMessage = `Error creating offer`;
    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }
    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getOffersFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetOffers() {
  yield takeEvery(GET_OFFERS, getOffers);
}

export function* watchGetCurrOffer() {
  yield takeEvery(GET_CURR_OFFER, getCurrOffer);
}

export function* watchPatchOffer() {
  yield takeEvery(PATCH_OFFER, patchOffer);
}

export function* watchPostOffer() {
  yield takeEvery(POST_OFFER, postOffer);
}

export function* watchGetFiltering() {
  yield takeEvery(OFF_FILTER_BY_VALUE, getFiltered);
}

function* offersSaga() {
  yield all([
    fork(watchGetOffers),
    fork(watchPostOffer),
    fork(watchGetCurrOffer),
    fork(watchPatchOffer),
    fork(watchGetFiltering),
  ]);
}

export default offersSaga;
