import {
  API_ERROR,
  GET_IMPS_CLICKS,
  GET_IMPS_CLICKS_SUCCESS,
  GET_REV_PAY,
  GET_REV_PAY_SUCCESS,
  GET_REV_PAY_DATES,
  GET_REV_PAY_DATES_SUCCESS,
  GET_REV_PAY_PID,
  GET_REV_PAY_PID_SUCCESS,
  GET_REV_PAY_ADV,
  GET_REV_PAY_ADV_SUCCESS,
  GET_REV_PAY_GROUP_ADV,
  GET_REV_PAY_GROUP_ADV_SUCCESS,
  GET_REV_PAY_GROUP_PID,
  GET_REV_PAY_GROUP_PID_SUCCESS,
} from './actionsTypes';

// Get Imps CLicks
export const getImpsClicks = (data) => {
  return {
    type: GET_IMPS_CLICKS,
    payload: data,
  };
};
export const getImpsClicksSuccess = (data) => {
  return {
    type: GET_IMPS_CLICKS_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay
export const getRevPay = (data) => {
  return {
    type: GET_REV_PAY,
    payload: data,
  };
};
export const getRevPaySuccess = (data) => {
  return {
    type: GET_REV_PAY_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay Dates
export const getRevPayDates = (data) => {
  return {
    type: GET_REV_PAY_DATES,
    payload: data,
  };
};
export const getRevPayDatesSuccess = (data) => {
  return {
    type: GET_REV_PAY_DATES_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay Pid
export const getRevPayPid = (data) => {
  return {
    type: GET_REV_PAY_PID,
    payload: data,
  };
};
export const getRevPayPidSuccess = (data) => {
  return {
    type: GET_REV_PAY_PID_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay Adv
export const getRevPayAdv = (data) => {
  return {
    type: GET_REV_PAY_ADV,
    payload: data,
  };
};
export const getRevPayAdvSuccess = (data) => {
  return {
    type: GET_REV_PAY_ADV_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay Adv
export const getRevPayGroupAdv = (data) => {
  return {
    type: GET_REV_PAY_GROUP_ADV,
    payload: data,
  };
};
export const getRevPayGroupAdvSuccess = (data) => {
  return {
    type: GET_REV_PAY_GROUP_ADV_SUCCESS,
    payload: data,
  };
};

// Get Rev Pay Adv
export const getRevPayGroupPid = (data) => {
  return {
    type: GET_REV_PAY_GROUP_PID,
    payload: data,
  };
};
export const getRevPayGroupPidSuccess = (data) => {
  return {
    type: GET_REV_PAY_GROUP_PID_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
