export const VERIFY_JWT = 'VERIFY_JWT';
export const VERIFY_JWT_SUCCESSFUL = 'VERIFY_JWT_SUCCESSFUL';

export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const API_ERROR = 'api_failed';
