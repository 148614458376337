import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { apiError, setCurrentSelectedValuesSuccess } from './actions';

// Action Types
import { SET_DROPDOWN_VALUES } from './actionsTypes';

//If user is login then dispatch redux action's are directly from here.

function* getDropdownValue(data) {
  try {
    yield put(setCurrentSelectedValuesSuccess(data.payload));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetDropdownValue() {
  yield takeEvery(SET_DROPDOWN_VALUES, getDropdownValue);
}

function* advertisersSaga() {
  yield all([fork(watchGetDropdownValue)]);
}

export default advertisersSaga;
