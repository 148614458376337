import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// Dashboard
// import Dashboard from '../pages/Dashboard/index';
// import AfAppInfo from '../pages/Dashboard/afData/AfAppInfo';

// // Authentication related pages
// import Login from '../pages/Authentication/Login';
// // PAGES
// // Offers
// import Offers from '../pages/Offers/Offers';
// import OffersInfo from '../pages/Offers/components/OffersInfo';

// // Campaigns
// import Campaigns from '../pages/Campaigns/Campaigns';
// import CampaignsInfo from '../pages/Campaigns/components/CampaignsInfo';

// // Advertisers
// import Advertisers from '../pages/Advertisers/Advertisers';
// // Publishers
// import Publishers from '../pages/Publishers/Publishers';
// import AdvertInfo from '../pages/Advertisers/components/AdvertInfo';
// import PublisherInfo from '../pages/Publishers/components/PublisherInfo';
// import Numbers from '../pages/Numbers/Numbers';

// Dashboard
const Dashboard = lazy(() => import('../pages/Dashboard/index'));
const AfAppInfo = lazy(() => import('../pages/Dashboard/afData/AfAppInfo'));
// // Authentication related pages
const Login = lazy(() => import('../pages/Authentication/Login'));
const Logout = lazy(() => import('../pages/Authentication/Logout'));
// // PAGES
const Offers = lazy(() => import('../pages/Offers/Offers'));
const OffersInfo = lazy(() => import('../pages/Offers/components/OffersInfo'));
const Campaigns = lazy(() => import('../pages/Campaigns/Campaigns'));
const CampaignsInfo = lazy(() =>
  import('../pages/Campaigns/components/CampaignsInfo')
);
const Advertisers = lazy(() => import('../pages/Advertisers/Advertisers'));
const AdvertInfo = lazy(() =>
  import('../pages/Advertisers/components/AdvertInfo')
);
const Publishers = lazy(() => import('../pages/Publishers/Publishers'));
const PublisherInfo = lazy(() =>
  import('../pages/Publishers/components/PublisherInfo')
);
const Numbers = lazy(() => import('../pages/Numbers/Numbers'));

// import Logout from '../pages/Authentication/Logout';
// import Register from '../pages/Authentication/Register';
// import ForgetPwd from '../pages/Authentication/ForgetPassword';
// import AuthLockScreen from '../pages/Authentication/AuthLockScreen';

// Pages Component
// import Chat from '../pages/Samples/Chat/Chat';

// Ecommerce Pages
// import Products from '../pages/Samples/Ecommerce/Products';
// import ProductDetail from '../pages/Samples/Ecommerce/ProductDetail';
// import Orders from '../pages/Samples/Ecommerce/Orders';
// import Customers from '../pages/Samples/Ecommerce/Customers';
// import Cart from '../pages/Samples/Ecommerce/Cart';
// import CheckOut from '../pages/Samples/Ecommerce/CheckOut';
// import Shops from '../pages/Samples/Ecommerce/Shops';
// import AddProduct from '../pages/Samples/Ecommerce/AddProduct';

// Email
// import EmailInbox from '../pages/Samples/Email/email-inbox';
// import EmailRead from '../pages/Samples/Email/email-read';

// Charts
// import ChartApex from '../pages/Samples/Charts/Apexcharts';
// import ChartjsChart from '../pages/Samples/Charts/ChartjsChart';
// import SparklineChart from '../pages/Samples/Charts/SparklineChart';
// import ChartsKnob from '../pages/Samples/Charts/charts-knob';

// Maps
// import MapsGoogle from '../pages/Samples/Maps/MapsGoogle';
// import MapsVector from '../pages/Samples/Maps/MapsVector';

// Icons
// import RemixIcons from '../pages/Samples/Icons/RemixIcons';
// import MaterialDesign from '../pages/Samples/Icons/MaterialDesign';
// import DripiIcons from '../pages/Samples/Icons/DripiIcons';
// import FontAwesome from '../pages/Samples/Icons/FontAwesome';

// Utility
// import StarterPage from '../pages/Samples/Utility/StarterPage';
// import Maintenance from '../pages/Samples/Utility/Maintenance';
// import CommingSoon from '../pages/Samples/Utility/CommingSoon';
// import Timeline from '../pages/Samples/Utility/Timeline';
// import FAQs from '../pages/Samples/Utility/FAQs';
// import Pricing from '../pages/Samples/Utility/Pricing';
// import Error404 from '../pages/Samples/Utility/Error404';
// import Error500 from '../pages/Samples/Utility/Error500';

// Forms
// import FormElements from '../pages/Samples/Forms/FormElements';
// import FormAdvanced from '../pages/Samples/Forms/FormAdvanced';
// import FormEditors from '../pages/Samples/Forms/FormEditors';
// import FormValidations from '../pages/Samples/Forms/FormValidations';
// import FormMask from '../pages/Samples/Forms/FormMask';
// import FormUpload from '../pages/Samples/Forms/FormUpload';
// import FormWizard from '../pages/Samples/Forms/FormWizard';
// import FormXeditable from '../pages/Samples/Forms/FormXeditable';

// Ui
// import UiAlert from '../pages/Samples/Ui/UiAlert';
// import UiButtons from '../pages/Samples/Ui/UiButtons';
// import UiCards from '../pages/Samples/Ui/UiCards';
// import UiCarousel from '../pages/Samples/Ui/UiCarousel';
// import UiDropdown from '../pages/Samples/Ui/UiDropdown';
// import UiGeneral from '../pages/Samples/Ui/UiGeneral';
// import UiGrid from '../pages/Samples/Ui/UiGrid';
// import UiImages from '../pages/Samples/Ui/UiImages';
// import UiLightbox from '../pages/Samples/Ui/UiLightbox';
// import UiModal from '../pages/Samples/Ui/UiModal';
// import UiProgressbar from '../pages/Samples/Ui/UiProgressbar';
// import UiSweetAlert from '../pages/Samples/Ui/UiSweetAlert';
// import UiTabsAccordions from '../pages/Samples/Ui/UiTabsAccordions';
// import UiTypography from '../pages/Samples/Ui/UiTypography';
// import UiVideo from '../pages/Samples/Ui/UiVideo';
// import UiSessionTimeout from '../pages/Samples/Ui/UiSessionTimeout';
// import UiRating from '../pages/Samples/Ui/UiRating';
// import UiNotifications from '../pages/Samples/Ui/ui-notifications';
// import UIRoundSlider from '../pages/Samples/Ui/UIRoundSlider';

// Tables
// import BasicTables from '../pages/Samples/Tables/BasicTables';
// import DatatableTables from '../pages/Samples/Tables/DatatableTables';
// import ResponsiveTables from '../pages/Samples/Tables/ResponsiveTables';
// import EditableTables from '../pages/Samples/Tables/EditableTables';

// Inner Authentication
// import Login1 from '../pages/AuthenticationInner/Login';
// import Register1 from '../pages/AuthenticationInner/Register';
// import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';

const authProtectedRoutes = [
  // // Tables
  // { path: '/tables-basic', component: BasicTables },
  // { path: '/tables-datatable', component: DatatableTables },
  // { path: '/tables-responsive', component: ResponsiveTables },
  // { path: '/tables-editable', component: EditableTables },

  // // Ui
  // { path: '/ui-alerts', component: UiAlert },
  // { path: '/ui-buttons', component: UiButtons },
  // { path: '/ui-cards', component: UiCards },
  // { path: '/ui-carousel', component: UiCarousel },
  // { path: '/ui-dropdowns', component: UiDropdown },
  // { path: '/ui-general', component: UiGeneral },
  // { path: '/ui-grid', component: UiGrid },
  // { path: '/ui-images', component: UiImages },
  // { path: '/ui-lightbox', component: UiLightbox },
  // { path: '/ui-modals', component: UiModal },
  // { path: '/ui-progressbars', component: UiProgressbar },
  // { path: '/ui-sweet-alert', component: UiSweetAlert },
  // { path: '/ui-tabs-accordions', component: UiTabsAccordions },
  // { path: '/ui-typography', component: UiTypography },
  // { path: '/ui-video', component: UiVideo },
  // { path: '/ui-session-timeout', component: UiSessionTimeout },
  // { path: '/ui-rating', component: UiRating },
  // { path: '/ui-notifications', component: UiNotifications },
  // { path: '/ui-roundslider', component: UIRoundSlider },

  // // Forms
  // { path: '/form-elements', component: FormElements },
  // { path: '/form-advanced', component: FormAdvanced },
  // { path: '/form-editors', component: FormEditors },
  // { path: '/form-mask', component: FormMask },
  // { path: '/form-uploads', component: FormUpload },
  // { path: '/form-wizard', component: FormWizard },
  // { path: '/form-validation', component: FormValidations },
  // { path: '/form-xeditable', component: FormXeditable },

  // // Utility
  // { path: '/pages-starter', component: StarterPage },
  // { path: '/pages-timeline', component: Timeline },
  // { path: '/pages-faqs', component: FAQs },
  // { path: '/pages-pricing', component: Pricing },

  // // Icons
  // { path: '/icons-remix', component: RemixIcons },
  // { path: '/icons-materialdesign', component: MaterialDesign },
  // { path: '/icons-dripicons', component: DripiIcons },
  // { path: '/icons-fontawesome', component: FontAwesome },

  // // Maps
  // { path: '/maps-google', component: MapsGoogle },
  // { path: '/maps-vector', component: MapsVector },

  // // Charts
  // { path: '/charts-apex', component: ChartApex },
  // { path: '/charts-chartjs', component: ChartjsChart },
  // { path: '/charts-sparkline', component: SparklineChart },
  // { path: '/charts-knob', component: ChartsKnob },

  // // Email
  // { path: '/email-inbox', component: EmailInbox },
  // { path: '/email-read', component: EmailRead },

  // // Ecommerce
  // { path: '/ecommerce-products', component: Products },
  // { path: '/ecommerce-product-detail', component: ProductDetail },
  // { path: '/ecommerce-orders', component: Orders },
  // { path: '/ecommerce-customers', component: Customers },
  // { path: '/ecommerce-cart', component: Cart },
  // { path: '/ecommerce-checkout', component: CheckOut },
  // { path: '/ecommerce-shops', component: Shops },
  // { path: '/ecommerce-add-product', component: AddProduct },

  // // chat
  // { path: '/apps-chat', component: Chat },

  { path: '/dashboard', component: Dashboard, exact: true },
  { path: '/dashboard/:pid/:app', component: AfAppInfo, exact: true },

  // offers
  { path: '/offers', component: Offers, exact: true },
  { path: '/offers/:id', component: OffersInfo, exact: true },

  // campaigns
  { path: '/campaigns', component: Campaigns, exact: true },
  { path: '/campaigns/:id', component: CampaignsInfo, exact: true },

  // advertisers
  { path: '/advertisers', exact: true, component: Advertisers },
  { path: '/advertisers/:id', exact: true, component: AdvertInfo },

  // publishers
  { path: '/publishers', exact: true, component: Publishers },
  { path: '/publishers/:id', exact: true, component: PublisherInfo },

  // numbers
  { path: '/numbers', exact: true, component: Numbers },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  // { path: '/forgot-password', component: ForgetPwd },
  // { path: '/register', component: Register },
  // { path: '/auth-lock-screen', component: AuthLockScreen },

  // Authentication Inner
  // { path: '/auth-login', component: Login1 },
  // { path: '/auth-register', component: Register1 },
  // { path: '/auth-recoverpw', component: ForgetPwd1 },

  // { path: '/pages-maintenance', component: Maintenance },
  // { path: '/pages-comingsoon', component: CommingSoon },
  // { path: '/pages-404', component: Error404 },
  // { path: '/pages-500', component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
