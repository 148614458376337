import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Publishers
const getPublishersRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Current Publisher
const getCurrPublisherRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Patch Publishers
const patchPublisherRequest = (url, data) => {
  // console.log(url, data);

  // let fd = new FormData();

  // for (var key in data)  {
  //   fd.append(key, data[key]);
  //   console.log(key, data[key]);
  // }
  // console.log(fd);

  return axios
    .put(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Publishers
const postPublisherRequest = (url, data) => {
  return axios
    .post(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Publishers
const deletePublisherRequest = (url) => {
  return axios
    .delete(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export {
  getPublishersRequest,
  getCurrPublisherRequest,
  patchPublisherRequest,
  postPublisherRequest,
  deletePublisherRequest,
};
