import {
  API_ERROR,
  GET_AF,
  GET_AF_SUCCESS,
  GET_AF_GLOBAL,
  GET_AF_GLOBAL_SUCCESS,
  AF_FILTER_BY_VALUE,
  AF_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllAf = (data) => {
  return {
    type: GET_AF,
    payload: data,
  };
};
export const getAllAfSuccess = (data) => {
  return {
    type: GET_AF_SUCCESS,
    payload: data,
  };
};

// Get Global
export const getAfGlobal = (data) => {
  return {
    type: GET_AF_GLOBAL,
    payload: data,
  };
};
export const getAfGlobalSuccess = (data) => {
  return {
    type: GET_AF_GLOBAL_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getAfFilteredValues = (data) => {
  return {
    type: AF_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getAfFilteredSuccess = (data) => {
  return {
    type: AF_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
