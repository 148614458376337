import {
  API_ERROR,
  GET_AF,
  GET_AF_SUCCESS,
  GET_AF_GLOBAL,
  GET_AF_GLOBAL_SUCCESS,
  AF_FILTER_BY_VALUE,
  AF_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  afData: [],
  afGlobalData: [],
  afError: null,
  message: null,
  loadingAfData: null,
  loadingAfGlobalData: null,
  filteredData: '',
};

const AfData = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_AF:
      state = {
        ...state,
        afData: [],
        loadingAfData: true,
        afError: null,
      };
      break;

    case GET_AF_SUCCESS:
      state = {
        ...state,
        afData: action.payload,
        loadingAfData: false,
        afError: null,
      };
      break;

    //   GET GLOBAL
    case GET_AF_GLOBAL:
      state = {
        ...state,
        afGlobalData: [],
        loadingAfGlobalData: true,
        afError: null,
      };
      break;

    case GET_AF_GLOBAL_SUCCESS:
      state = {
        ...state,
        afGlobalData: action.payload,
        loadingAfGlobalData: false,
        afError: null,
      };
      break;

    case AF_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: null,
        loadingFilter: true,
        afError: null,
      };
      break;
    case AF_FILTER_BY_VALUE_SUCCESS:
      let value = action.payload;

      state = {
        ...state,
        filteredData: value,
        loadingFilter: false,
        afError: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingAfData: null,
        loadingFilter: null,
        afError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AfData;
