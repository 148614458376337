import {
  API_ERROR,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_CURR_OFFER,
  GET_CURR_OFFER_SUCCESS,
  PATCH_OFFER,
  PATCH_OFFER_SUCCESS,
  POST_OFFER,
  POST_OFFER_SUCCESS,
  OFF_FILTER_BY_VALUE,
  OFF_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllOffers = (data) => {
  return {
    type: GET_OFFERS,
    payload: data,
  };
};
export const getAllOffersSuccess = (data) => {
  return {
    type: GET_OFFERS_SUCCESS,
    payload: data,
  };
};

// Get Current
export const getCurrOffer = (data) => {
  return {
    type: GET_CURR_OFFER,
    payload: data,
  };
};
export const getCurrOfferSuccess = (data) => {
  return {
    type: GET_CURR_OFFER_SUCCESS,
    payload: data,
  };
};

// PATCH
export const patchOffer = (id, data) => {
  return {
    type: PATCH_OFFER,
    payload: {
      id: id,
      data: data,
    },
  };
};
export const patchOfferSuccess = (data) => {
  return {
    type: PATCH_OFFER_SUCCESS,
    payload: data,
  };
};

// POST
export const postOffer = (data) => {
  return {
    type: POST_OFFER,
    payload: data,
  };
};
export const postOfferSuccess = (data) => {
  return {
    type: POST_OFFER_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getOffersFilteredValues = (data) => {
  return {
    type: OFF_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getOffersFilteredSuccess = (data) => {
  return {
    type: OFF_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
