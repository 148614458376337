import {
  API_ERROR,
  GET_ADVERTISERS,
  GET_ADVERTISERS_SUCCESS,
  GET_CURR_ADVERTISER,
  GET_CURR_ADVERTISER_SUCCESS,
  PATCH_ADVERTISER,
  PATCH_ADVERTISER_SUCCESS,
  POST_ADVERTISER,
  POST_ADVERTISER_SUCCESS,
  ADV_FILTER_BY_VALUE,
  ADV_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  advertisersData: [],
  currData: {},
  filteredData: '',
  error: null,
  message: null,
  loadingAdvertsData: null,
  // loadingCurr: null,
};

const Advertisers = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_ADVERTISERS:
      state = {
        ...state,
        advertisersData: [],
        loadingAdvertsData: true,
        error: null,
      };
      break;

    case GET_ADVERTISERS_SUCCESS:
      state = {
        ...state,
        advertisersData: action.payload,
        loadingAdvertsData: false,
        error: null,
      };
      break;

    //   GET CURR
    case GET_CURR_ADVERTISER:
      state = {
        ...state,
        currData: {},
        loadingCurr: true,
        error: null,
      };
      break;

    case GET_CURR_ADVERTISER_SUCCESS:
      state = {
        ...state,
        currData: action.payload,
        loadingCurr: false,
        error: null,
      };
      break;

    //   PATCH
    case PATCH_ADVERTISER:
      state = {
        ...state,
        patchData: null,
        loadingUpdate: true,
        error: null,
      };
      break;

    case PATCH_ADVERTISER_SUCCESS:
      state = {
        ...state,
        patchData: action.payload,
        loadingUpdate: false,
        error: null,
      };
      break;

    //   POST
    case POST_ADVERTISER:
      state = {
        ...state,
        postData: null,
        loadingPost: true,
        error: null,
      };
      break;

    case POST_ADVERTISER_SUCCESS:
      state = {
        ...state,
        postData: action.payload,
        loadingPost: false,
        error: null,
      };
      break;

    case ADV_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: '',
        loadingFilter: true,
        error: null,
      };
      break;
    case ADV_FILTER_BY_VALUE_SUCCESS:
      state = {
        ...state,
        filteredData: action.payload,
        loadingFilter: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingAdvertsData: false,
        loadingCurr: false,
        loadingUpdate: false,
        loadingPost: false,
        loadingFilter: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Advertisers;
