export const GET_PUBLISHERS = 'GET_PUBLISHERS';
export const GET_PUBLISHERS_SUCCESS = 'GET_PUBLISHERS_SUCCESS';

export const GET_CURR_PUBLISHER = 'GET_CURR_PUBLISHER';
export const GET_CURR_PUBLISHER_SUCCESS = 'GET_CURR_PUBLISHER_SUCCESS';

export const POST_PUBLISHER = 'POST_PUBLISHER';
export const POST_PUBLISHER_SUCCESS = 'POST_PUBLISHER_SUCCESS';

export const PATCH_PUBLISHER = 'PATCH_PUBLISHER';
export const PATCH_PUBLISHER_SUCCESS = 'PATCH_PUBLISHER_SUCCESS';

export const PUB_FILTER_BY_VALUE = 'PUB_FILTER_BY_VALUE';
export const PUB_FILTER_BY_VALUE_SUCCESS = 'PUB_FILTER_BY_VALUE_SUCCESS';

export const API_ERROR = 'API_ERROR';
