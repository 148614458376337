import {
  API_ERROR,
  GET_PUBLISHERS,
  GET_PUBLISHERS_SUCCESS,
  GET_CURR_PUBLISHER,
  GET_CURR_PUBLISHER_SUCCESS,
  PATCH_PUBLISHER,
  PATCH_PUBLISHER_SUCCESS,
  POST_PUBLISHER,
  POST_PUBLISHER_SUCCESS,
  PUB_FILTER_BY_VALUE,
  PUB_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  publishersData: [],
  currData: {},
  error: null,
  message: null,
  loadingPublishData: null,
  filteredData: '',
};

const Publishers = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_PUBLISHERS:
      state = {
        ...state,
        publishersData: [],
        loadingPublishData: true,
        error: null,
      };
      break;

    case GET_PUBLISHERS_SUCCESS:
      state = {
        ...state,
        publishersData: action.payload,
        loadingPublishData: false,
        error: null,
      };
      break;

    //   GET CURR
    case GET_CURR_PUBLISHER:
      state = {
        ...state,
        currData: {},
        loadingCurr: true,
        error: null,
      };
      break;

    case GET_CURR_PUBLISHER_SUCCESS:
      state = {
        ...state,
        currData: action.payload,
        loadingCurr: false,
        error: null,
      };
      break;

    //   PATCH
    case PATCH_PUBLISHER:
      state = {
        ...state,
        patchData: null,
        loadingUpdate: true,
        error: null,
      };
      break;

    case PATCH_PUBLISHER_SUCCESS:
      state = {
        ...state,
        patchData: action.payload,
        loadingUpdate: false,
        error: null,
      };
      break;

    //   POST
    case POST_PUBLISHER:
      state = {
        ...state,
        postData: null,
        loadingPost: true,
        error: null,
      };
      break;

    case POST_PUBLISHER_SUCCESS:
      state = {
        ...state,
        postData: action.payload,
        loadingPost: false,
        error: null,
      };
      break;

    case PUB_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: null,
        loadingFiltered: true,
        error: null,
      };
      break;
    case PUB_FILTER_BY_VALUE_SUCCESS:
      let value = action.payload;

      state = {
        ...state,
        filteredData: value,
        loadingFiltered: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingPublishData: false,
        loadingCurr: false,
        loadingPost: false,
        loadingUpdate: false,
        loadingFiltered: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Publishers;
