import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER, VERIFY_JWT } from './actionTypes';
import { apiError, loginUserSuccessful } from './actions';

// AUTH related methods
import { postLogin, verifyJWT } from '../../../helpers/api/Auth';
import { toastHandlerError } from '../../../helpers/toastHandler/toastHandler';

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, '/auth/login', {
      email: user.email,
      password: user.password,
    });

    localStorage.setItem('JWT', response);
    yield put(loginUserSuccessful(response));

    history.push('/dashboard');
  } catch (error) {
    let errorMessage = `Error signin in with ${user.email}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);
    yield put(apiError(error));
  }
}

function* verifyUser({ payload: { history } }) {
  try {
    yield call(verifyJWT, '/auth/verify');

    // yield put(verifyJWTSuccessful(response));
    // history.push('/dashboard');
  } catch (error) {
    localStorage.removeItem('JWT');
    history.push('/login');
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('JWT');

    // yield put(logoutUserSuccess(response));

    history.push('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserVerify() {
  yield takeEvery(VERIFY_JWT, verifyUser);
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchUserVerify),
  ]);
}

export default loginSaga;
