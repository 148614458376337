import {
  API_ERROR,
  GET_NUMBERS,
  GET_NUMBERS_SUCCESS,
  NUM_FILTER_BY_VALUE,
  NUM_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllNumbers = (data) => {
  return {
    type: GET_NUMBERS,
    payload: data,
  };
};
export const getAllNumbersSuccess = (data) => {
  return {
    type: GET_NUMBERS_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getNumbersFilteredValues = (data) => {
  return {
    type: NUM_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getNumbersFilteredSuccess = (data) => {
  return {
    type: NUM_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
