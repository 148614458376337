import {
  API_ERROR,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_ACTIVE_CAMPAIGNS,
  GET_ACTIVE_CAMPAIGNS_SUCCESS,
  GET_CURR_CAMPAIGN,
  GET_CURR_CAMPAIGN_SUCCESS,
  PATCH_CAMPAIGN,
  PATCH_CAMPAIGN_SUCCESS,
  POST_CAMPAIGN,
  POST_CAMPAIGN_SUCCESS,
  CAMP_FILTER_BY_VALUE,
  CAMP_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  campaignsData: [],
  activeCampaignsData: [],
  error: null,
  message: null,
  loading: null,
  filteredData: '',
};

const Campaigns = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_CAMPAIGNS:
      state = {
        ...state,
        campaignsData: [],
        loadingCampaignsData: true,
        error: null,
      };
      break;

    case GET_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        campaignsData: action.payload,
        loadingCampaignsData: false,
        error: null,
      };
      break;

    //   GET ALL ACTIVE
    case GET_ACTIVE_CAMPAIGNS:
      state = {
        ...state,
        activeCampaignsData: [],
        loadingActiveCampaignsData: true,
        error: null,
      };
      break;

    case GET_ACTIVE_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        activeCampaignsData: action.payload,
        loadingActiveCampaignsData: false,
        error: null,
      };
      break;

    //   GET CURR
    case GET_CURR_CAMPAIGN:
      state = {
        ...state,
        currData: null,
        loadingCampaignCurr: true,
        error: null,
      };
      break;

    case GET_CURR_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        currData: action.payload,
        loadingCampaignCurr: false,
        error: null,
      };
      break;

    //   PATCH
    case PATCH_CAMPAIGN:
      state = {
        ...state,
        patchData: null,
        loadingUpdate: true,
        error: null,
      };
      break;

    case PATCH_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        patchData: action.payload,
        loadingUpdate: false,
        error: null,
      };
      break;

    //   POST
    case POST_CAMPAIGN:
      state = {
        ...state,
        postData: null,
        loadingPost: true,
        error: null,
      };
      break;

    case POST_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        postData: action.payload,
        loadingPost: false,
        error: null,
      };
      break;

    case CAMP_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: null,
        loadingFilter: true,
        error: null,
      };
      break;
    case CAMP_FILTER_BY_VALUE_SUCCESS:
      let value = action.payload;

      state = {
        ...state,
        filteredData: value,
        loadingFilter: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingCampaignsData: false,
        loadingActiveCampaignsData: false,
        loadingCampaignCurr: false,
        loadingUpdate: false,
        loadingPost: false,
        loadingFilter: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Campaigns;
