import React from 'react';
import { Toaster } from 'react-hot-toast';

// import { ToastContainer } from 'react-toastify';

const CustomToastContainer = () => {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 7500,
        style: {
          background: '#252b3b',
          color: '#fff',
        },
        // success: {
        //    style: {
        //       background: '#ded7c5',
        //       // color: '#060506',
        //    },
        // },
        // error: {
        //    style: {
        //       background: '#ded7c5',
        //       // color: '#060506',
        //    },
        // },
      }}
    />
  );
};

export default CustomToastContainer;
