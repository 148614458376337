import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Campaigns
const getCampaignsRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Active Campaigns
const getActiveCampaignsRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Current Campaign
const getCurrCampaignRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Patch Campaigns
const patchCampaignRequest = (url, data) => {
  return axios
    .put(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Campaigns
const postCampaignRequest = (url, data) => {
  return axios
    .post(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Campaigns
const deleteCampaignRequest = (url) => {
  return axios
    .delete(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export {
  getCampaignsRequest,
  getActiveCampaignsRequest,
  getCurrCampaignRequest,
  patchCampaignRequest,
  postCampaignRequest,
  deleteCampaignRequest,
};
