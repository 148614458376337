import {
  API_ERROR,
  SET_DROPDOWN_VALUES,
  SET_DROPDOWN_VALUES_SUCCESS,
} from './actionsTypes';

// Get All
export const setCurrentSelectedValues = (data) => {
  return { type: SET_DROPDOWN_VALUES, payload: data };
};

export const setCurrentSelectedValuesSuccess = (data) => {
  return {
    type: SET_DROPDOWN_VALUES_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
