import {
  API_ERROR,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_CURR_OFFER,
  GET_CURR_OFFER_SUCCESS,
  PATCH_OFFER,
  PATCH_OFFER_SUCCESS,
  POST_OFFER,
  POST_OFFER_SUCCESS,
  OFF_FILTER_BY_VALUE,
  OFF_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  offersData: [],
  error: null,
  message: null,
  loadingOffersData: null,
  filteredData: '',
};

const Offers = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_OFFERS:
      state = {
        ...state,
        offersData: [],
        loadingOffersData: true,
        error: null,
      };
      break;

    case GET_OFFERS_SUCCESS:
      state = {
        ...state,
        offersData: action.payload,
        loadingOffersData: false,
        error: null,
      };
      break;

    //   GET CURR
    case GET_CURR_OFFER:
      state = {
        ...state,
        currData: null,
        loadingOfferCurr: true,
        error: null,
      };
      break;

    case GET_CURR_OFFER_SUCCESS:
      state = {
        ...state,
        currData: action.payload,
        loadingOfferCurr: false,
        error: null,
      };
      break;

    //   PATCH
    case PATCH_OFFER:
      state = {
        ...state,
        patchData: null,
        loadingUpdate: true,
        error: null,
      };
      break;

    case PATCH_OFFER_SUCCESS:
      state = {
        ...state,
        patchData: action.payload,
        loadingUpdate: false,
        error: null,
      };
      break;

    //   POST
    case POST_OFFER:
      state = {
        ...state,
        postData: null,
        loadingPost: true,
        error: null,
      };
      break;

    case POST_OFFER_SUCCESS:
      state = {
        ...state,
        postData: action.payload,
        loadingPost: false,
        error: null,
      };
      break;

    case OFF_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: null,
        loadingFilter: true,
        error: null,
      };
      break;
    case OFF_FILTER_BY_VALUE_SUCCESS:
      let value = action.payload;

      state = {
        ...state,
        filteredData: value,
        loadingFilter: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingOffersData: false,
        loadingOfferCurr: false,
        loadingUpdate: false,
        loadingPost: false,
        loadingFilter: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Offers;
