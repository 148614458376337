import {
  API_ERROR,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_ACTIVE_CAMPAIGNS,
  GET_ACTIVE_CAMPAIGNS_SUCCESS,
  GET_CURR_CAMPAIGN,
  GET_CURR_CAMPAIGN_SUCCESS,
  PATCH_CAMPAIGN,
  PATCH_CAMPAIGN_SUCCESS,
  POST_CAMPAIGN,
  POST_CAMPAIGN_SUCCESS,
  CAMP_FILTER_BY_VALUE,
  CAMP_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllCampaigns = (data) => {
  return {
    type: GET_CAMPAIGNS,
    payload: data,
  };
};
export const getAllCampaignsSuccess = (data) => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: data,
  };
};

// Get All Active
export const getAllActiveCampaigns = (data) => {
  return {
    type: GET_ACTIVE_CAMPAIGNS,
    payload: data,
  };
};
export const getAllActiveCampaignsSuccess = (data) => {
  return {
    type: GET_ACTIVE_CAMPAIGNS_SUCCESS,
    payload: data,
  };
};

// Get Current
export const getCurrCampaign = (data) => {
  return {
    type: GET_CURR_CAMPAIGN,
    payload: data,
  };
};
export const getCurrCampaignSuccess = (data) => {
  return {
    type: GET_CURR_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

// PATCH
export const patchCampaign = (id, data) => {
  return {
    type: PATCH_CAMPAIGN,
    payload: {
      id: id,
      data: data,
    },
  };
};
export const patchCampaignSuccess = (data) => {
  return {
    type: PATCH_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

// POST
export const postCampaign = (data) => {
  return {
    type: POST_CAMPAIGN,
    payload: data,
  };
};
export const postCampaignSuccess = (data) => {
  return {
    type: POST_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getCampaignsFilteredValues = (data) => {
  return {
    type: CAMP_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getCampaignsFilteredSuccess = (data) => {
  return {
    type: CAMP_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
