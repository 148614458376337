import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getAllPublishersSuccess,
  getCurrPublisherSuccess,
  patchPublisherSuccess,
  postPublisherSuccess,
  getPublishFilteredSuccess,
} from './actions';

// Action Types
import {
  GET_PUBLISHERS,
  GET_CURR_PUBLISHER,
  PATCH_PUBLISHER,
  POST_PUBLISHER,
  PUB_FILTER_BY_VALUE,
} from './actionsTypes';

// Methods
import {
  getPublishersRequest,
  getCurrPublisherRequest,
  patchPublisherRequest,
  postPublisherRequest,
} from '../../helpers/api/Publishers';
import checkFieldValues from '../../helpers/checkings/checkFieldValues';
import {
  toastHandlerError,
  toastHandlerSuccess,
} from '../../helpers/toastHandler/toastHandler';

// Get
function* getPublishers() {
  try {
    const response = yield call(getPublishersRequest, '/publishers');
    yield put(getAllPublishersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Get Curr
function* getCurrPublisher(data) {
  try {
    const response = yield call(
      getCurrPublisherRequest,
      `/publishers/${data.payload}`
    );
    yield put(getCurrPublisherSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Patch
function* patchPublisher(data) {
  try {
    const response = yield call(
      patchPublisherRequest,
      `/publishers/${data.payload.id}`,
      checkFieldValues(data.payload.data)
    );
    toastHandlerSuccess(`Publisher ${response.company} updated successfully!`);
    yield put(patchPublisherSuccess(response));
  } catch (error) {
    let errorMessage = `Error updating publisher ${data.payload.id}`;
    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }
    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

// Post
function* postPublisher(data) {
  try {
    const response = yield call(
      postPublisherRequest,
      '/publishers',
      data.payload
    );

    toastHandlerSuccess(`Publisher ${response.company} created successfully!`);

    yield put(postPublisherSuccess(response));
  } catch (error) {
    let errorMessage = `Error creating publisher ${data.payload.id}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getPublishFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetPublishers() {
  yield takeEvery(GET_PUBLISHERS, getPublishers);
}

export function* watchGetCurrPublisher() {
  yield takeEvery(GET_CURR_PUBLISHER, getCurrPublisher);
}

export function* watchPatchPublisher() {
  yield takeEvery(PATCH_PUBLISHER, patchPublisher);
}

export function* watchPostPublisher() {
  yield takeEvery(POST_PUBLISHER, postPublisher);
}

export function* watchGetFiltering() {
  yield takeEvery(PUB_FILTER_BY_VALUE, getFiltered);
}

function* publishersSaga() {
  yield all([
    fork(watchGetPublishers),
    fork(watchPostPublisher),
    fork(watchGetCurrPublisher),
    fork(watchPatchPublisher),
    fork(watchGetFiltering),
  ]);
}

export default publishersSaga;
