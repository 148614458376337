import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Components
import toast from 'react-hot-toast';

// Login Redux States
import {
  apiError,
  getAllAdvertisersSuccess,
  getCurrAdvertiserSuccess,
  patchAdvertiserSuccess,
  postAdvertiserSuccess,
  getAdvertFilteredSuccess,
} from './actions';

// Action Types
import {
  GET_ADVERTISERS,
  GET_CURR_ADVERTISER,
  PATCH_ADVERTISER,
  ADV_FILTER_BY_VALUE,
} from './actionsTypes';
import { POST_ADVERTISER } from './actionsTypes';

// Methods
import {
  getAdvertisersRequest,
  getCurrAdvertiserRequest,
  patchAdvertiserRequest,
  postAdvertiserRequest,
} from '../../helpers/api/Advertisers';
import checkFieldValues from '../../helpers/checkings/checkFieldValues';
import {
  toastHandlerError,
  toastHandlerSuccess,
} from '../../helpers/toastHandler/toastHandler';

// Get
function* getAdvertisers() {
  try {
    const response = yield call(getAdvertisersRequest, '/advertisers');

    yield put(getAllAdvertisersSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Get Curr
function* getCurrAdvertiser(data) {
  try {
    const response = yield call(
      getCurrAdvertiserRequest,
      `/advertisers/${data.payload}`
    );

    yield put(getCurrAdvertiserSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Patch
function* patchAdvertiser(data) {
  try {
    const response = yield call(
      patchAdvertiserRequest,
      `/advertisers/${data.payload.id}`,
      checkFieldValues(data.payload.data)
    );

    toastHandlerSuccess(
      `¡Advertiser ${response.company} updated successfully!`
    );
    yield put(patchAdvertiserSuccess(response));
  } catch (error) {
    let errorMessage = `Error updating advertiser ${data.payload.id}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

// Post
function* postAdvertiser(data) {
  try {
    const response = yield call(
      postAdvertiserRequest,
      '/advertisers',
      data.payload
    );

    toastHandlerSuccess(
      `¡Advertiser ${response.company} created successfully!`
    );
    yield put(postAdvertiserSuccess(response));
  } catch (error) {
    let errorMessage = `Error creating advertiser ${data.payload.company}`;

    if (error.response) {
      errorMessage = errorMessage + ': ' + error.response.data.message;
    }

    toastHandlerError(errorMessage);

    yield put(apiError(error));
  }
}

function* getFiltered(data) {
  try {
    yield put(getAdvertFilteredSuccess(data.payload.value));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetAdvertisers() {
  yield takeEvery(GET_ADVERTISERS, getAdvertisers);
}

export function* watchGetCurrAdvertiser() {
  yield takeEvery(GET_CURR_ADVERTISER, getCurrAdvertiser);
}

export function* watchPatchAdvertiser() {
  yield takeEvery(PATCH_ADVERTISER, patchAdvertiser);
}

export function* watchPostAdvertiser() {
  yield takeEvery(POST_ADVERTISER, postAdvertiser);
}

export function* watchGetFiltering() {
  yield takeEvery(ADV_FILTER_BY_VALUE, getFiltered);
}

function* advertisersSaga() {
  yield all([
    fork(watchGetAdvertisers),
    fork(watchPostAdvertiser),
    fork(watchGetCurrAdvertiser),
    fork(watchPatchAdvertiser),
    fork(watchGetFiltering),
  ]);
}

export default advertisersSaga;
