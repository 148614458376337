import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Offers
const getOffersRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Current Offer
const getCurrOfferRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Patch Offers
const patchOfferRequest = (url, data) => {
  return axios
    .put(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Offers
const postOfferRequest = (url, data) => {
  return axios
    .post(apiUrl + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Post Offers
const deleteOfferRequest = (url) => {
  return axios
    .delete(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export {
  getOffersRequest,
  getCurrOfferRequest,
  patchOfferRequest,
  postOfferRequest,
  deleteOfferRequest,
};
