import {
  API_ERROR,
  GET_ADVERTISERS,
  GET_ADVERTISERS_SUCCESS,
  GET_CURR_ADVERTISER,
  GET_CURR_ADVERTISER_SUCCESS,
  PATCH_ADVERTISER,
  PATCH_ADVERTISER_SUCCESS,
  POST_ADVERTISER,
  POST_ADVERTISER_SUCCESS,
  ADV_FILTER_BY_VALUE,
  ADV_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllAdvertisers = (data) => {
  return {
    type: GET_ADVERTISERS,
    payload: data,
  };
};
export const getAllAdvertisersSuccess = (data) => {
  return {
    type: GET_ADVERTISERS_SUCCESS,
    payload: data,
  };
};

// Get Current
export const getCurrAdvertiser = (data) => {
  return {
    type: GET_CURR_ADVERTISER,
    payload: data,
  };
};
export const getCurrAdvertiserSuccess = (data) => {
  return {
    type: GET_CURR_ADVERTISER_SUCCESS,
    payload: data,
  };
};

// PATCH
export const patchAdvertiser = (id, data) => {
  return {
    type: PATCH_ADVERTISER,
    payload: {
      id: id,
      data: data,
    },
  };
};
export const patchAdvertiserSuccess = (data) => {
  return {
    type: PATCH_ADVERTISER_SUCCESS,
    payload: data,
  };
};

// POST
export const postAdvertiser = (data) => {
  return {
    type: POST_ADVERTISER,
    payload: data,
  };
};
export const postAdvertiserSuccess = (data) => {
  return {
    type: POST_ADVERTISER_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getAdvertFilteredValues = (data) => {
  return {
    type: ADV_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getAdvertFilteredSuccess = (data) => {
  return {
    type: ADV_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
