const objectMap = (object, mapFn) => {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};

const checkFieldValues = (data) => {
  return objectMap(data, function (value) {
    return value === '' ? null : value;
  });
};

export default checkFieldValues;
