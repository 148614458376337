import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Numbers
const getNumbersRequest = (url, data) => {
  return axios
    .get(apiUrl + url, { params: data })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export { getNumbersRequest };
