export const GET_IMPS_CLICKS = 'GET_IMPS_CLICKS';
export const GET_IMPS_CLICKS_SUCCESS = 'GET_IMPS_CLICKS_SUCCESS';

export const GET_REV_PAY = 'GET_REV_PAY';
export const GET_REV_PAY_SUCCESS = 'GET_REV_PAY_SUCCESS';

export const GET_REV_PAY_DATES = 'GET_REV_PAY_DATES';
export const GET_REV_PAY_DATES_SUCCESS = 'GET_REV_PAY_DATES_SUCCESS';

export const GET_REV_PAY_PID = 'GET_REV_PAY_PID';
export const GET_REV_PAY_PID_SUCCESS = 'GET_REV_PAY_PID_SUCCESS';

export const GET_REV_PAY_ADV = 'GET_REV_PAY_ADV';
export const GET_REV_PAY_ADV_SUCCESS = 'GET_REV_PAY_ADV_SUCCESS';

export const GET_REV_PAY_GROUP_ADV = 'GET_REV_PAY_GROUP_ADV';
export const GET_REV_PAY_GROUP_ADV_SUCCESS = 'GET_REV_PAY_GROUP_ADV_SUCCESS';

export const GET_REV_PAY_GROUP_PID = 'GET_REV_PAY_GROUP_PID';
export const GET_REV_PAY_GROUP_PID_SUCCESS = 'GET_REV_PAY_GROUP_PID_SUCCESS';

export const API_ERROR = 'API_ERROR';
