export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';

export const GET_ACTIVE_CAMPAIGNS = 'GET_ACTIVE_CAMPAIGNS';
export const GET_ACTIVE_CAMPAIGNS_SUCCESS = 'GET_ACTIVE_CAMPAIGNS_SUCCESS';

export const GET_CURR_CAMPAIGN = 'GET_CURR_CAMPAIGN';
export const GET_CURR_CAMPAIGN_SUCCESS = 'GET_CURR_CAMPAIGN_SUCCESS';

export const POST_CAMPAIGN = 'POST_CAMPAIGN';
export const POST_CAMPAIGN_SUCCESS = 'POST_CAMPAIGN_SUCCESS';

export const PATCH_CAMPAIGN = 'PATCH_CAMPAIGN';
export const PATCH_CAMPAIGN_SUCCESS = 'PATCH_CAMPAIGN_SUCCESS';

export const CAMP_FILTER_BY_VALUE = 'CAMP_FILTER_BY_VALUE';
export const CAMP_FILTER_BY_VALUE_SUCCESS = 'CAMP_FILTER_BY_VALUE_SUCCESS';

export const API_ERROR = 'API_ERROR';
