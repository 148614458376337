import {
  API_ERROR,
  GET_NUMBERS,
  GET_NUMBERS_SUCCESS,
  NUM_FILTER_BY_VALUE,
  NUM_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

const initialState = {
  numbersData: [],
  error: null,
  message: null,
  loading: null,
  filteredData: '',
};

const Numbers = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case GET_NUMBERS:
      state = {
        ...state,
        numbersData: [],
        loadingNumbersData: true,
        error: null,
      };
      break;

    case GET_NUMBERS_SUCCESS:
      state = {
        ...state,
        numbersData: action.payload,
        loadingNumbersData: false,
        error: null,
      };
      break;

    case NUM_FILTER_BY_VALUE:
      state = {
        ...state,
        filteredData: null,
        loadingFilter: true,
        error: null,
      };
      break;
    case NUM_FILTER_BY_VALUE_SUCCESS:
      let value = action.payload;

      state = {
        ...state,
        filteredData: value,
        loadingFilter: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingNumbersData: false,
        loadingFilter: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Numbers;
