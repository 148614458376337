import {
  API_ERROR,
  GET_PUBLISHERS,
  GET_PUBLISHERS_SUCCESS,
  GET_CURR_PUBLISHER,
  GET_CURR_PUBLISHER_SUCCESS,
  PATCH_PUBLISHER,
  PATCH_PUBLISHER_SUCCESS,
  POST_PUBLISHER,
  POST_PUBLISHER_SUCCESS,
  PUB_FILTER_BY_VALUE,
  PUB_FILTER_BY_VALUE_SUCCESS,
} from './actionsTypes';

// Get All
export const getAllPublishers = (data) => {
  return {
    type: GET_PUBLISHERS,
    payload: data,
  };
};
export const getAllPublishersSuccess = (data) => {
  return {
    type: GET_PUBLISHERS_SUCCESS,
    payload: data,
  };
};

// Get Current
export const getCurrPublisher = (data) => {
  return {
    type: GET_CURR_PUBLISHER,
    payload: data,
  };
};
export const getCurrPublisherSuccess = (data) => {
  return {
    type: GET_CURR_PUBLISHER_SUCCESS,
    payload: data,
  };
};

// PATCH
export const patchPublisher = (id, data) => {
  return {
    type: PATCH_PUBLISHER,
    payload: {
      id: id,
      data: data,
    },
  };
};
export const patchPublisherSuccess = (data) => {
  return {
    type: PATCH_PUBLISHER_SUCCESS,
    payload: data,
  };
};

// POST
export const postPublisher = (data) => {
  return {
    type: POST_PUBLISHER,
    payload: data,
  };
};
export const postPublisherSuccess = (data) => {
  return {
    type: POST_PUBLISHER_SUCCESS,
    payload: data,
  };
};

// Filter By value
export const getPublishFilteredValues = (data) => {
  return {
    type: PUB_FILTER_BY_VALUE,
    payload: data,
  };
};
export const getPublishFilteredSuccess = (data) => {
  return {
    type: PUB_FILTER_BY_VALUE_SUCCESS,
    payload: data,
  };
};

// FAILURE
export const apiError = (data) => {
  return {
    type: API_ERROR,
    payload: data,
  };
};
