import {
  API_ERROR,
  SET_DROPDOWN_VALUES,
  SET_DROPDOWN_VALUES_SUCCESS,
} from './actionsTypes';

const initialState = {
  dropdownValue: [],
  loading: null,
  error: null,
  message: null,
};

const Advertisers = (state = initialState, action) => {
  switch (action.type) {
    //   GET ALL
    case SET_DROPDOWN_VALUES:
      state = {
        ...state,
        dropdownValue: [],
        loading: true,
        error: null,
      };
      break;

    case SET_DROPDOWN_VALUES_SUCCESS:
      state = {
        ...state,
        dropdownValue: action.payload,
        loading: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Advertisers;
