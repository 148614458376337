export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';

export const GET_CURR_OFFER = 'GET_CURR_OFFER';
export const GET_CURR_OFFER_SUCCESS = 'GET_CURR_OFFER_SUCCESS';

export const POST_OFFER = 'POST_OFFER';
export const POST_OFFER_SUCCESS = 'POST_OFFER_SUCCESS';

export const PATCH_OFFER = 'PATCH_OFFER';
export const PATCH_OFFER_SUCCESS = 'PATCH_OFFER_SUCCESS';

export const OFF_FILTER_BY_VALUE = 'OFF_FILTER_BY_VALUE';
export const OFF_FILTER_BY_VALUE_SUCCESS = 'OFF_FILTER_BY_VALUE_SUCCESS';

export const API_ERROR = 'API_ERROR';
