import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  apiError,
  getImpsClicksSuccess,
  getRevPaySuccess,
  getRevPayDatesSuccess,
  getRevPayPidSuccess,
  getRevPayAdvSuccess,
  getRevPayGroupAdvSuccess,
  getRevPayGroupPidSuccess,
} from './actions';

// Action Types
import {
  GET_IMPS_CLICKS,
  GET_REV_PAY,
  GET_REV_PAY_DATES,
  GET_REV_PAY_PID,
  GET_REV_PAY_ADV,
  GET_REV_PAY_GROUP_ADV,
  GET_REV_PAY_GROUP_PID,
} from './actionsTypes';

// Methods
import { getRequest } from '../../helpers/api/Overview.js';

//If user is login then dispatch redux action's are directly from here.

// Get
function* getImpsClicks(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/impsClicks',
      data.payload
    );

    yield put(getImpsClicksSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPay(data) {
  try {
    const response = yield call(getRequest, '/overview/revPay', data.payload);

    yield put(getRevPaySuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPayDates(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/revPay/dates',
      data.payload
    );

    yield put(getRevPayDatesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPayPid(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/revPay/publishers',
      data.payload
    );

    yield put(getRevPayPidSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPayAdv(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/revPay/advertisers',
      data.payload
    );

    yield put(getRevPayAdvSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPayGroupAdv(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/revPay/group/advertisers',
      data.payload
    );

    yield put(getRevPayGroupAdvSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRevPayGroupPid(data) {
  try {
    const response = yield call(
      getRequest,
      '/overview/revPay/group/publishers',
      data.payload
    );

    yield put(getRevPayGroupPidSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetImpsClicks() {
  yield takeEvery(GET_IMPS_CLICKS, getImpsClicks);
}

export function* watchGetRevPay() {
  yield takeEvery(GET_REV_PAY, getRevPay);
}

export function* watchGetRevPayDates() {
  yield takeEvery(GET_REV_PAY_DATES, getRevPayDates);
}

export function* watchGetRevPayPid() {
  yield takeEvery(GET_REV_PAY_PID, getRevPayPid);
}

export function* watchGetRevPayAdv() {
  yield takeEvery(GET_REV_PAY_ADV, getRevPayAdv);
}

export function* watchGetRevPayGroupAdv() {
  yield takeEvery(GET_REV_PAY_GROUP_ADV, getRevPayGroupAdv);
}

export function* watchGetRevPayGroupPid() {
  yield takeEvery(GET_REV_PAY_GROUP_PID, getRevPayGroupPid);
}

function* advertisersSaga() {
  yield all([
    fork(watchGetImpsClicks),
    fork(watchGetRevPay),
    fork(watchGetRevPayDates),
    fork(watchGetRevPayPid),
    fork(watchGetRevPayAdv),
    fork(watchGetRevPayGroupAdv),
    fork(watchGetRevPayGroupPid),
  ]);
}

export default advertisersSaga;
