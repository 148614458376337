export const GET_AF = 'GET_AF';
export const GET_AF_SUCCESS = 'GET_AF_SUCCESS';

export const GET_AF_GLOBAL = 'GET_AF_GLOBAL';
export const GET_AF_GLOBAL_SUCCESS = 'GET_AF_GLOBAL_SUCCESS';

export const AF_FILTER_BY_VALUE = 'AF_FILTER_BY_VALUE';
export const AF_FILTER_BY_VALUE_SUCCESS = 'AF_FILTER_BY_VALUE_SUCCESS';

export const API_ERROR = 'API_ERROR';
