import {
  API_ERROR,
  GET_IMPS_CLICKS,
  GET_IMPS_CLICKS_SUCCESS,
  GET_REV_PAY,
  GET_REV_PAY_SUCCESS,
  GET_REV_PAY_DATES,
  GET_REV_PAY_DATES_SUCCESS,
  GET_REV_PAY_PID,
  GET_REV_PAY_PID_SUCCESS,
  GET_REV_PAY_ADV,
  GET_REV_PAY_ADV_SUCCESS,
  GET_REV_PAY_GROUP_ADV,
  GET_REV_PAY_GROUP_ADV_SUCCESS,
  GET_REV_PAY_GROUP_PID,
  GET_REV_PAY_GROUP_PID_SUCCESS,
} from './actionsTypes';

const initialState = {
  impsClicksData: [],
  revPayData: [],
  revPayDatesData: [],
  revPayGroupAdvData: [],
  revPayGroupPidData: [],
  loadingImpsClicksData: null,
  loadingRevPayData: null,
  loadingRevPayDatesData: null,
  loadingRevPayPidData: null,
  loadingRevPayAdvData: null,
  loadingRevPayGroupAdvData: null,
  loadingRevPayGroupPidData: null,
  error: null,
  message: null,
  // loadingCurr: null,
};

const Advertisers = (state = initialState, action) => {
  switch (action.type) {
    //   GET IMPS CLICKS
    case GET_IMPS_CLICKS:
      state = {
        ...state,
        impsClicksData: [],
        loadingImpsClicksData: true,
        error: null,
      };
      break;

    case GET_IMPS_CLICKS_SUCCESS:
      state = {
        ...state,
        impsClicksData: action.payload,
        loadingImpsClicksData: false,
        error: null,
      };
      break;

    // GET REV PAY
    case GET_REV_PAY:
      state = {
        ...state,
        revPayData: [],
        loadingRevPayData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_SUCCESS:
      state = {
        ...state,
        revPayData: action.payload,
        loadingRevPayData: false,
        error: null,
      };
      break;

    // GET REV PAY DATES
    case GET_REV_PAY_DATES:
      state = {
        ...state,
        revPayDatesData: [],
        loadingRevPayDatesData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_DATES_SUCCESS:
      state = {
        ...state,
        revPayDatesData: action.payload,
        loadingRevPayDatesData: false,
        error: null,
      };
      break;

    // GET REV PAY PID
    case GET_REV_PAY_PID:
      state = {
        ...state,
        revPayPidData: [],
        loadingRevPayPidData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_PID_SUCCESS:
      state = {
        ...state,
        revPayPidData: action.payload,
        loadingRevPayPidData: false,
        error: null,
      };
      break;

    // GET REV PAY ADV
    case GET_REV_PAY_ADV:
      state = {
        ...state,
        revPayAdvData: [],
        loadingRevPayAdvData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_ADV_SUCCESS:
      state = {
        ...state,
        revPayAdvData: action.payload,
        loadingRevPayAdvData: false,
        error: null,
      };
      break;

    // GET REV PAY GROUP ADV
    case GET_REV_PAY_GROUP_ADV:
      state = {
        ...state,
        revPayGroupAdvData: [],
        loadingRevPayGroupAdvData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_GROUP_ADV_SUCCESS:
      state = {
        ...state,
        revPayGroupAdvData: action.payload,
        loadingRevPayGroupAdvData: false,
        error: null,
      };
      break;

    // GET REV PAY GROUP PID
    case GET_REV_PAY_GROUP_PID:
      state = {
        ...state,
        revPayGroupPidData: [],
        loadingRevPayGroupPidData: true,
        error: null,
      };
      break;

    case GET_REV_PAY_GROUP_PID_SUCCESS:
      state = {
        ...state,
        revPayGroupPidData: action.payload,
        loadingRevPayGroupPidData: false,
        error: null,
      };
      break;

    case API_ERROR:
      state = {
        ...state,
        loadingImpsClicksData: null,
        loadingRevPayData: null,
        loadingRevPayDatesData: null,
        loadingRevPayPidData: null,
        loadingRevPayAdvData: null,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Advertisers;
