import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';
import Advertisers from './advertisers/reducer';
import Publishers from './publishers/reducer';
import Offers from './offers/reducer';
import Campaigns from './campaigns/reducer';
import Numbers from './numbers/reducer';
import Dropdown from './dropdown/reducer';
import Overview from './overview/reducer';
import AfData from './afData/reducer';

const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Account,
  Login,
  Forget,

  // Advertisers
  Advertisers,

  // Publishers
  Publishers,

  // Offers
  Offers,

  // Campaigns
  Campaigns,

  // Numbers
  Numbers,

  // Dropdown
  Dropdown,

  // Overview
  Overview,

  // AfData
  AfData,
});

export default rootReducer;
