import axios from 'axios';

export const apiUrl = 'https://inapi.appocket.net/api'; // http://inapidev.appocket.net

export const adminInstance = axios.create({
  baseURL: apiUrl,
});

adminInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('JWT');
    config.headers.Authorization = 'Bearer ' + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
