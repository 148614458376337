import { all } from 'redux-saga/effects';

//public
import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';
import forgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import advertisersSaga from './advertisers/saga';
import publishersSaga from './publishers/saga';
import offersSaga from './offers/saga';
import campaignsSaga from './campaigns/saga';
import numbersSaga from './numbers/saga';
import dropdownSaga from './dropdown/saga';
import overviewSaga from './overview/saga';
import afSaga from './afData/saga';

export default function* rootSaga() {
  yield all([
    //public
    accountSaga(),
    loginSaga(),
    forgetSaga(),
    LayoutSaga(),
    publishersSaga(),
    advertisersSaga(),
    offersSaga(),
    campaignsSaga(),
    numbersSaga(),
    dropdownSaga(),
    overviewSaga(),
    afSaga(),
  ]);
}
