export const GET_ADVERTISERS = 'GET_ADVERTISERS';
export const GET_ADVERTISERS_SUCCESS = 'GET_ADVERTISERS_SUCCESS';

export const GET_CURR_ADVERTISER = 'GET_CURR_ADVERTISER';
export const GET_CURR_ADVERTISER_SUCCESS = 'GET_CURR_ADVERTISER_SUCCESS';

export const POST_ADVERTISER = 'POST_ADVERTISER';
export const POST_ADVERTISER_SUCCESS = 'POST_ADVERTISER_SUCCESS';

export const PATCH_ADVERTISER = 'PATCH_ADVERTISER';
export const PATCH_ADVERTISER_SUCCESS = 'PATCH_ADVERTISER_SUCCESS';

export const ADV_FILTER_BY_VALUE = 'ADV_FILTER_BY_VALUE';
export const ADV_FILTER_BY_VALUE_SUCCESS = 'ADV_FILTER_BY_VALUE_SUCCESS';

export const API_ERROR = 'API_ERROR';
