import React, { useEffect, useState } from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// History
import history from './helpers/history/history';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './theme.scss';
import CustomToastContainer from './components/toastContainer/ToastContainer';

// ACTIONS
import { verifyJWT } from './store/actions';
import isMobile from './helpers/isMobile/isMobile';
import useIsMountedRef from './helpers/useIsMountedRef/useIsMountedRef';

//Fake backend
// import fakeBackend from './helpers/AuthType/fakeBackend';

// Activating fake backend
// fakeBackend();

const App = (props) => {
  const isMountedRef = useIsMountedRef();

  const [state, setState] = useState({ isMobile: '' });
  useEffect(() => {
    if (window.location.pathname !== '/login') {
      if (isMountedRef.current) {
        props.verifyJWT(props.history);
      }
    }
  }, [isMountedRef]);

  const setStateMobile = () => {
    return setState({
      ...state,
      isMobile: isMobile(),
    });
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (isMountedRef.current) {
        setStateMobile();
      }
    });
    return () => {
      window.removeEventListener('resize', () => {
        setStateMobile();
      });
    };
  }, [isMountedRef]);

  // useEffect(() => {
  //   // if (isMountedRef.current) {
  //   setTimeout(function () {
  //     document.getElementById('preloader').style.display = 'none';
  //     document.getElementById('status').style.display = 'none';
  //   }, 500);
  //   // }
  // }, [isMountedRef]);

  /**
   * Returns the layout
   */
  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => {
            return (
              <AppRoute
                path={route.path}
                exact={route.exact ? route.exact : false}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            );
          })}
          <Redirect to="/" />
        </Switch>
      </Router>

      <CustomToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default withRouter(connect(mapStateToProps, { verifyJWT })(App));
