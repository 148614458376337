import { adminInstance as axios } from '../../network/_axios';
import { apiUrl } from '../../network/_axios';

// Get Af
const getAfRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

// Get Af
const getAfGlobalRequest = (url) => {
  return axios
    .get(apiUrl + url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export { getAfRequest, getAfGlobalRequest };
